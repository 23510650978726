import axios from "axios";
import authHeader from "./services/auth-header";
import { refreshCashierToken } from "./services/auth.service";

export const BASE_URL = "https://ekobilet.pl";
//export const BASE_URL = "https://lab-ekobilet.pl";
// export const BASE_URL = "http://localhost:30080";
// export const BASE_URL = "http://adrian.lan:30080";
//export const BASE_URL = "http://krzysiek.lan:30080";

const BASE_URL_POS = `${BASE_URL}/api/v1/pos`;

export const gusBirInstance = axios.create({
  baseURL: `${BASE_URL}/api/v2/soap_requests`
});
gusBirInstance.defaults.headers.post["Content-Type"] = "application/json";

export const loginInstance = axios.create({
  baseURL: `${BASE_URL_POS}/tokens/cashier`
});
loginInstance.defaults.headers.post["Content-Type"] = "application/json";
loginInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    return false;
  }
);
export const logoutInstance = axios.create({
  baseURL: `${BASE_URL_POS}/tokens/logout_cashier`
});
logoutInstance.defaults.headers.post["Content-Type"] = "application/json";
logoutInstance.defaults.headers.common["Authorization"] = authHeader();
logoutInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    return false;
  }
);

export const baseInstance = axios.create({
  baseURL: `${BASE_URL_POS}/cashiers`
});
baseInstance.defaults.headers.common["Content-Type"] = "application/json";
baseInstance.defaults.headers.common["Authorization"] = authHeader();
baseInstance.interceptors.response.use(
  (response: any) => {
    refreshCashierToken(response);
    return response;
  },
  (error: any) => {
    const errorResponse = error.response;
    checkIfRedirectToMain(errorResponse);
    checkIfLogoutCashier(errorResponse);
    return errorResponse;
  }
);

const checkIfRedirectToMain = (error: any | undefined) => {
  if (error && error.status === 404) {
    window.location.href = "/cashier/not_found";
    console.log(error);
  }
};

const checkIfLogoutCashier = (error: any | undefined) => {
  const isUnauthorizedError = error?.status === 401 && error?.data?.message === "unauthorized";

  if (error && isUnauthorizedError) {
    localStorage.removeItem("cashier_token");
    localStorage.removeItem("drawer_balance");
    localStorage.removeItem("current_cashier");
    window.location.reload();
  }
};
